import React from "react";

const parseXMLStringToReactElements = (xmlString) => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xmlString, "text/xml");

  // Fonction récursive pour convertir les nœuds XML en éléments React
  const convertNodeToReactElement = (node) => {
    const nodeType = node.nodeType;

    if (nodeType === Node.ELEMENT_NODE) {
      const elementName = node.tagName;
      const attributes = Array.from(node.attributes).reduce(
        (acc, { name, value }) => ({ ...acc, [name]: value }),
        {}
      );
      const children = Array.from(node.childNodes).map(
        convertNodeToReactElement
      );

      return React.createElement(elementName, attributes, ...children);
    } else if (nodeType === Node.TEXT_NODE) {
      return node.textContent;
    }

    return null;
  };

  // Récupérer le nœud racine et commencer la conversion
  const rootNode = xmlDoc.documentElement;
  return convertNodeToReactElement(rootNode);
};

export default parseXMLStringToReactElements;
