import React, {useState, useEffect, useRef} from "react";
import "./Work.css";
import WorkList from "../Components/WorkList";
import useMousePosition from "../Utils/useMousePosition";
import Video from "../Components/Video";
import AdminEdition from '../Components/AdminEdition'
import {skillGet, workGet} from "../Utils/Api";
import { useNavigate } from "react-router-dom";

export default function Work(props) {
    const navigate = useNavigate()
    const absMousePosition = useMousePosition();

    const workContainerRef = useRef(null);
    const previewRef = useRef(null);
    const [relativeMousePosition, setRelativeMousePosition] = useState([0, 0]);
    const [maxLeft, setMaxLeft] = useState([0, 0]);
    const [showPreview, setShowPreview] = useState(false);
    const [isNavigating, setIsNavigating] = useState(false);
    const [workArray, setWorkArray] = useState([]);
    const [skillArray, setSkillArray] = useState([]);

    const [videoSrc, setVideoSrc] = useState(null);
    const [updateData, setUpdateData] = useState(false);
    const [isTouchDevice, setIsTouchDevice] = useState(false);

    const handleStateChange = (val) => {
        setVideoSrc(val)
    };

    const handleNavigation = (val) => {
        setIsNavigating(true)
        setTimeout(() => {
            navigate('/portfolio/' + val)
        }, 550)
    };

    function handleHover(bool) {
        if (showPreview !== bool) {
            setShowPreview(bool);
        }
    }

    function onUpdateData(){
        setUpdateData(!updateData)
    }

    useEffect(() => {
        workGet().then(res => {
            setWorkArray(res)
        })
        skillGet()
            .then((res) => {
                setSkillArray(res)
            })
        setIsTouchDevice('maxTouchPoints' in navigator && navigator.maxTouchPoints > 0);
    }, []);

    useEffect(() => {
        if (workContainerRef && !isTouchDevice) {
            const x = absMousePosition.x - workContainerRef.current.offsetLeft;
            const y = absMousePosition.y - workContainerRef.current.offsetTop;
            let posArray = [x, y];

            posArray.forEach((e, i) => {
                e < 0 ? (posArray[i] = 0) : (posArray[i] = e);

                const condition =
                    i > 0
                        ? workContainerRef.current.offsetHeight
                        : workContainerRef.current.offsetWidth;
                e > condition ? (posArray[i] = condition) : (posArray[i] = e);
            });
            setRelativeMousePosition(posArray);
            if (previewRef) {
                setMaxLeft(window.innerWidth - (previewRef.current.offsetWidth + 30))
            }
        }

    }, [previewRef, workContainerRef, absMousePosition, isTouchDevice]);

    useEffect(() => {
        if (previewRef && !isTouchDevice) {
            const overViewElStyle = previewRef.current.style;
            if (!isNavigating){
                overViewElStyle.left = Math.min((relativeMousePosition[0] + 20), maxLeft) + 'px'; //20 represente la distance entre la souris et le overview
                overViewElStyle.top = relativeMousePosition[1] + 20 + 'px'
                overViewElStyle.opacity = showPreview ? 1 : 0
            } else {
                overViewElStyle.transition = 'all 0.5s ease-in-out';
                overViewElStyle.left = 0
                overViewElStyle.top = 0
                overViewElStyle.width = '100%'
                overViewElStyle.maxHeight = '60vh'
            }
        }
    }, [relativeMousePosition, previewRef, isNavigating, isTouchDevice]);

    return (
        <section ref={workContainerRef} className="work-container">
            <div>
                <div
                    className="project-list"
                    onMouseEnter={() => handleHover(true)}
                    onMouseLeave={() => handleHover(false)}
                >
                    {workArray.length > 0 ? workArray.map((e) => {
                        return (
                            <WorkList
                                key={e.id}
                                id={e.id}
                                title={e.title}
                                technicalStack={e.technicalStack}
                                hostingDate={e.hostingDate}
                                demoLink={e.demoLink}
                                onHover={handleStateChange}
                                onNavigate={handleNavigation}
                                isAdmin={props.isAdmin}
                                skills={skillArray}
                            />
                        );
                    }) : <></>}
                </div>
            </div>
            {
                props.isAdmin &&
                <AdminEdition
                    topic='add-work'
                    method='POST'
                    onUpdate={onUpdateData}
                />
            }

            {!isTouchDevice &&
                <div
                    ref={previewRef}
                    className="project-overview"
                >
                    <Video
                        id="myVideo"
                        muted
                        loop
                        src={videoSrc}
                        overView={true}
                        type="video/webm"
                        error='Votre navitgateur ne supporte pas les video HTML5.'
                    />
                </div>
            }
        </section>
    );
}
