import React from "react";
import "./Button.css";

export default function Button(props) {
  return (
    <button className="btn-main" value={props.value} type={props.type} onClick={props.onClick}>
      {props.children}
    </button>
  );
}
