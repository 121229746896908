import React from "react";
import Input from "../Components/Input";
import Button from "../Components/Button";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import "./Login.css";

export default function Login(props) {
  const navigate = useNavigate();

  async function login(data) {
    const res = await axios.post(`https://api.zeghoudi-mohammed-walid.fr/auth/login`, data);
    let result = await res.data;
    if (res.status === 200) {
      localStorage.setItem("token", result.token);
      props.isLogged(true)
      navigate("/");
    } else if (res.status === 404 || res.status === 401) {
      console.error(res);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const reqObj = {};
    formData.forEach((e, key) => {
      reqObj[key] = e;
    });
    login(reqObj);
  };

  return (
    <section id="login-section">
      <form id="login-form" onSubmit={handleSubmit} autoComplete='on'>
        <Input type="email" name="email" label='Email' required />
        <Input type="password" name="password" label='Password' required />
        <Button type="submit">Submit</Button>
      </form>
    </section>
  );
}
