import React, {useEffect, useState} from "react";
import "./WorkList.css";
import AdminEdition from './AdminEdition'
import parseXMLStringToReactElements from "../Utils/parseXMLStringToReactElements";

export default function WorkList({onHover, onNavigate, ...props}) {
    const [isTouchDevice, setIsTouchDevice] = useState(false);

    useEffect(() => {
        setIsTouchDevice('maxTouchPoints' in navigator && navigator.maxTouchPoints > 0);
    }, []);

    const handleHoverChanges = () => {
        onHover(props.demoLink);
    };

    return (
        <div
            key={props.key}
            className={isTouchDevice ? 'touch workList-el' : 'workList-el'}
            onMouseEnter={handleHoverChanges}
            onClick={() => {
                onNavigate(props.id)
            }}
        >
            <div className="workList-title">{props.title}</div>
            <div className="workList-data">
                <div className="logo">
                    {props.isAdmin &&
                        <div className='admin-actions'>
                            <AdminEdition topic='edit-work'/>
                            <AdminEdition topic='delete-work'/>
                        </div>
                    }
                    {props.skills.length > 0 && props.technicalStack.map((e) => {
                        const skillObject = props.skills.find(
                            (skill) => skill.id === e.skillId
                        );
                        return (
                            <div key={skillObject.id} className="svgClass">
                                {parseXMLStringToReactElements(skillObject.svgCode)}
                            </div>
                        );
                    })}
                </div>
                <div className="date">{props.hostingDate}</div>
            </div>
        </div>
    );
}
