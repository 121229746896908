import React from 'react'
import './NotFound.css'
import {useNavigate} from "react-router-dom";

export default function NotFound() {
    const navigate = useNavigate()
  return (
    <section id='notfound-section' className="notfound-container">
      <div className="presentation">
        Oups! <br />
        Ce lien semble introuvable
      </div>
      <div className="objectives">
        <button className='back-to-main-btn' onClick={() => navigate('/')}>Retour à l'accueil</button>
      </div>
    </section>
  )
}
