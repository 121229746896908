import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../View/Home";
import Skills from "../View/Skills";
import Contact from "../View/Contact";
import Work from "../View/Work";
import Project from "../View/Project";
import Login from "../View/Login";
import NotFound from "../View/NotFound";

export default function Routing(props) {
    return (
        <Routes>
            <Route path="/" element={<Home isAdmin={props.isAdmin}/>} />
            <Route path="/login" element={<Login isAdmin={props.isAdmin} isLogged={props.isLogged}/>} />
            <Route path="/competences" element={<Skills isAdmin={props.isAdmin}/>} />
            <Route path="/contact" element={<Contact isAdmin={props.isAdmin}/>} />
            <Route path="/portfolio" element={<Work isAdmin={props.isAdmin}/>} />
            <Route path="/portfolio/:id" element={<Project isAdmin={props.isAdmin} getProjectInfo={props.getProjectInfo}/>} />
            <Route path="/*" element={<NotFound isAdmin={props.isAdmin}/>} />
            <Route path="/not-found" element={<NotFound isAdmin={props.isAdmin}/>} />
        </Routes>
    );
}
