import React, { useRef, useState, useEffect } from "react";
import "./InfoBar.css";

export default function InfoBar(props) {
  const contentRef = useRef(null);
  const [contentWidth, setContentWidth] = useState(0);
  const [contentHeight, setContentHeight] = useState(0);
  const [duplicatedContent, setDuplicatedContent] = useState(null);
  const [isContentReady, setIsContentReady] = useState(false);


  useEffect(() => {
    if (contentRef.current) setContentWidth(contentRef.current.offsetWidth);
    if (isContentReady)
      setContentHeight(contentRef.current.children[0].offsetHeight + 10);
  }, [contentRef, isContentReady]);

  useEffect(() => {
    if (props.mode === 'ANNOUNCE') {
      const messageCount = Math.round(window.innerWidth / 200);
      let messageTxt = "";
      for (let i = 0; i < messageCount; i++) {
        messageTxt = (
          <>
            {messageTxt}
            <span>{props.message}</span>
          </>
        );
      }

      let tempContent = (
        <>
          <div className="message transition">{messageTxt}</div>
          <div className="message transition">{messageTxt}</div>
          <div className="message transition">{messageTxt}</div>
        </>
      );
      setDuplicatedContent(tempContent);
      setIsContentReady(true);
    } else {
      let tempContent = (
          <>
            <div className="message transition">Mode admin</div>
            <button className='logoff-btn' onClick={()=>{
              localStorage.removeItem("token")
              props.isLogged(false)
            }}>logOff</button>
          </>
      )
      setDuplicatedContent(tempContent);
      setIsContentReady(true);
    }
  }, [contentRef]);

  function animation() {
    const el = Array.from(contentRef.current.children);
    const transitionClass = "message transition";
    const noTransitionClass = "message";

    el.forEach((e) => {
      if (e.offsetLeft < 0) {
        e.className = noTransitionClass;
        e.style.left = contentWidth + "px";
      } else {
        e.className = transitionClass;
        e.style.left = e.offsetLeft - contentWidth + "px";
      }
    });
    setTimeout(() => {
      animation();
    }, 10000);
  }

  useEffect(() => {
    if (isContentReady && props.mode === 'ANNOUNCE') animation();
  }, [isContentReady]);


  return (
    <div
      ref={contentRef}
      id="info-bar"
      className={props.mode === 'ADMIN' ? 'admin-bar' : ''}
      style={{ height: `${contentHeight}px` }}
    >
      {duplicatedContent}
    </div>
  );
}
