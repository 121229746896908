import React, {useEffect, useState} from "react";
import "./Input.css";


export default function Input(props) {
  const [isFilled, setIsFilled] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setIsFilled(inputValue !== "");
    if (props.onChange) {
      props.onChange(inputValue);
    }
  };

  useEffect(() => {
    if (props.value){
      setInputValue(props.value)
      setIsFilled(true)
    }
  }, [props.value]);

  return (
    <div className="input-container">
      { props.type !== 'textArea' ?
          <input
              type={props.type}
              name={props.name}
              className="form__input"
              required={props.required}
              onChange={handleInputChange}
              defaultValue={inputValue}
          />
          :
          <textarea
              name={props.name}
              className="form__input"
              rows={props.rows}
              required={props.required}
              onChange={handleInputChange}
              defaultValue={inputValue}
          >
          </textarea>
      }
      <label htmlFor={props.type} className={isFilled ? "form__label active" : "form__label"}>
          {props.required ? props.label + ' *' : props.label}
      </label>
    </div>
  );
}
