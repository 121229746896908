import React, {useEffect, useRef, useState} from "react";
import './AdminEdition.css'
import Input from "./Input";
import DatePicker from "./DatePicker";
import Button from "./Button";

import {faAdd, faPenToSquare, faXmark, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {skillDelete, skillOptions, skillPosts, workPost} from "../Utils/Api";
import MultiSelectOption from "./MultiSelectOption";

export default function AdminEdition({onWorkOpen, onUpdate, ...props}) {
    const [adminBtn, setAdminBtn] = useState(null)
    const [modalForm, setModalForm] = useState(null)
    const [modalTitle, setModalTitle] = useState('')
    const [optionsSkill, setOptionsSkill] = useState([])
    const [isOptionReady, setIsOptionReady] = useState(false)
    const [selectedOptions, setSelectedOptions] = useState([])
    const dialogRef = useRef(null)

    useEffect(() => {
        skillOptions().then(res => {
            setOptionsSkill(res)
            setIsOptionReady(true)
        })
    }, []);

    const [workDialog, setWorkDialog] = useState(
        <form onSubmit={(e) => handleWorkSubmit(e, props.method, workPost)}>
            <Input type="text" name="title" label="Titre du projet"/>
            <Input type="textArea" name="description" label="Description du projet"/>
            <Input type="text" name="repoLink" label="Lien Git du projet"/>
            <Input type="text" name="hostedLink" label="Lien du projet"/>
            <Input type="text" name="demoLink" label="Démonstration du projet"/>
            <MultiSelectOption  options={optionsSkill} getValues={getValues}/>
            <DatePicker name="hostingDate" label='Date de mise en prod'/>
            <Button type='submit'>Confirmer</Button>
        </form>
    )

    const [skillDialog, setSkillDialog] = useState(
        <form onSubmit={(e) => handleSkillSubmit(e, props.method, skillPosts)}>
            <Input type="text" name="title" label="Nom de la competences"/>
            <Input type="textArea" name="description" label="Description de la competences" rows={3}/>
            <Input type="textArea" name="svgCode" label="SVG" rows={3}/>
            <Button type='submit'>Confirmer</Button>
        </form>
    )

    function getValues(val){
        setSelectedOptions(val)
    }

    useEffect(() => {
        if (isOptionReady){
            if (props.data){
                setWorkDialog(
                    <form onSubmit={(e) => handleWorkSubmit(e, props.method)}>
                        <Input type="text" name="title" label="Titre du projet"/>
                        <Input type="textArea" name="description" label="Description du projet"/>
                        <Input type="text" name="repoLink" label="Lien Git du projet"/>
                        <Input type="text" name="hostedLink" label="Lien du projet"/>
                        <Input type="text" name="demoLink" label="Démonstration du projet"/>
                        <MultiSelectOption label="Stack technique" options={optionsSkill} getValues={getValues}/>
                        <DatePicker name="hostingDate" label='Date de mise en prod'/>
                        <Button type='submit'>Confirmer</Button>
                    </form>
                )
            } else {
                setWorkDialog(
                    <form onSubmit={(e) => handleWorkSubmit(e, props.method, workPost)}>
                        <Input type="text" name="title" label="Titre du projet"/>
                        <Input type="textArea" name="description" label="Description du projet"/>
                        <Input type="text" name="repoLink" label="Lien Git du projet"/>
                        <Input type="text" name="hostedLink" label="Lien du projet"/>
                        <Input type="text" name="demoLink" label="Démonstration du projet"/>
                        <MultiSelectOption label="Stack technique" options={optionsSkill} getValues={getValues}/>
                        <DatePicker name="hostingDate" label='Date de mise en prod'/>
                        <Button type='submit'>Confirmer</Button>
                    </form>
                )
            }
        }
        if (props.data) {
            setSkillDialog(
                <form onSubmit={(e) => handleSkillSubmit(e, props.method, skillPosts, props.data.id)}>
                    <Input type="text" name="title" label="Nom de la competences"
                           value={props.data.name ? props.data.name : ''}/>
                    <Input type="textArea" name="description" label="Description de la competences" rows={5}
                           value={props.data.description ? props.data.description : ''}/>
                    <Input type="textArea" name="svgCode" label="SVG" rows={5}
                           value={props.data.svgCode ? props.data.svgCode : ''}/>
                    <Button type='submit'>Confirmer</Button>
                </form>
            )
        }

    }, [props.data, isOptionReady]);

    const handleSkillSubmit = (e, method, apiCall, id = null) => {
        e.preventDefault();
        if (method === 'DELETE') {
            apiCall(id).then((res) => {
                onUpdate(res)
                dialogRef.current.close()
            })
        }
        else {
            const formData = new FormData(e.target);
            const reqObj = {};
            formData.forEach((e, key) => {
                reqObj[key] = e;
            });
            if (id) (reqObj.id = id)
            apiCall(method, reqObj).then((res) => {
                onUpdate(res)
                dialogRef.current.close()
            })
        }
    }

    const handleWorkSubmit =  (e, method, apiCall, id = null) => {
        e.preventDefault();
        if (method === 'DELETE') {
            apiCall(id).then((res) => {
                onUpdate(res)
                dialogRef.current.close()
            })
        }
        else {
            const formData = new FormData(e.target);
            const reqObj = {};
            formData.forEach((e, key) => {
                reqObj[key] = e;
            });
            reqObj.technicalStack = selectedOptions
            if (id) (reqObj.id = id)
            apiCall(method, reqObj).then((res) => {
                onUpdate(res)
                dialogRef.current.close()
            })
        }
    }

    useEffect(() => {
        if (props.topic) {
            switch (props.topic) {
                case 'add-work':
                    setModalTitle('Ajouter un projet')
                    setAdminBtn(
                        <Button>
                            ADD Project
                        </Button>
                    )
                    setModalForm(workDialog)
                    break
                case 'edit-work':
                    setModalTitle('Modifier le projet')
                    setAdminBtn(<FontAwesomeIcon className='admin-icon' icon={faPenToSquare}/>)
                    setModalForm(workDialog)
                    break
                case 'delete-work':
                    setModalTitle('Suppression de projet')
                    setAdminBtn(<FontAwesomeIcon className='admin-icon warning-color' icon={faTrash}/>)
                    break
                case 'add-skill':
                    setModalTitle('Ajouter une competence')
                    setAdminBtn(
                        <div className='edition-skill-btn'>
                            <FontAwesomeIcon className='admin-icon' icon={faAdd}/>
                        </div>
                    )
                    setModalForm(skillDialog)
                    break
                case 'edit-skill':
                    setModalTitle('Modifier une competence')
                    setAdminBtn(<FontAwesomeIcon className='admin-icon' icon={faPenToSquare}/>)
                    setModalForm(skillDialog)
                    break
                case 'delete-skill':
                    setModalTitle('Suppression de competence')
                    setAdminBtn(<FontAwesomeIcon className='admin-icon warning-color' icon={faTrash}/>)
                    setModalForm(
                        <form onSubmit={(e) => handleSkillSubmit(e, props.method, skillDelete, props.data.id)}>
                            <p className='delete-text-warning'>
                                Souhaitez vous reellement supprimer la competence ${props.data && props.data.name} ?
                                <br/>
                                Elle sera retirer des projets dans laquelle elle apparait
                            </p>
                            <Button type='submit'>Confirmer</Button>
                        </form>
                    )
                    break
                default:
                    setAdminBtn(null)
                    setModalForm(null)
                    break
            }
        }
    }, [props.topic, workDialog, skillDialog]);

    return (
        <>
            <div
                id='admin-edition'
                className="admin-edition"
                onClick={() => {
                    dialogRef.current.showModal()
                }}>
                {adminBtn}
            </div>
            <dialog
                ref={dialogRef}
                className='admin-modal'
                onClick={(e) => {
                    const modalSize = dialogRef.current.getBoundingClientRect()
                    if (
                        e.clientX < modalSize.left ||
                        e.clientX > modalSize.right ||
                        e.clientY < modalSize.top ||
                        e.clientY > modalSize.bottom) {
                        dialogRef.current.close()
                    }
                }}
            >
                <div className='modal-content'>
                    <h2 className='modal-title'>{modalTitle}</h2>
                    {modalForm}
                </div>
                <button
                    type='button'
                    className='close-modal-btn'
                    onClick={() => dialogRef.current.close()}
                >
                    <FontAwesomeIcon icon={faXmark}/>
                </button>
            </dialog>
        </>
    )
}