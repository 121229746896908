import React, {useEffect, useRef, useState} from "react";
import './Video.css'

export default function Video(props) {
    const videoRef = useRef(null)
    const [isMobile, setIsMobile] = useState(false)
    const [isTouchDevice, setIsTouchDevice] = useState(
        'maxTouchPoints' in navigator && navigator.maxTouchPoints > 0
    );

    useEffect(() => {
        if (window.innerWidth <= 500 && isTouchDevice){
            setIsMobile(true)
        }
    }, []);

    useEffect(() => {
        if (videoRef.current  && !props.overView) {
            if (!isMobile) {
                props.isPaused ? videoRef.current.firstChild.pause() : videoRef.current.firstChild.play()
            }
            props.isPaused ? videoRef.current.lastChild.pause() : videoRef.current.lastChild.play()
        }
    }, [videoRef, props.isPaused, isMobile]);


    return (
        <div key={props.src} ref={videoRef} className={'video-container'}>
            {!isMobile &&
                <video
                    className='backgroundVideo'
                    id={props.id}
                    muted={props.muted}
                    loop={props.loop}
                    autoPlay
                >
                    <source src={props.src} type={props.type}/>
                    {props.error}
                </video>
            }
            <video
                className='myVideo'
                id={props.id}
                muted={props.muted}
                loop={props.loop}
                autoPlay
            >
                <source src={props.src} type={props.type}/>
                {props.error}
            </video>
        </div>
    )
}