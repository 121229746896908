import React, {useEffect, useState} from "react";
import "./Contact.css";
import Input from "../Components/Input";
import Button from "../Components/Button";
import SelectOption from "../Components/SelectOption";
import DatePicker from "../Components/DatePicker";
import BudgetInput from "../Components/BudgetInput";
import LinkButton from "../Components/LinkButton";

import {contactMail} from '../Utils/Api'

import {faCodeBranch, faUnlink, faCheck, faXmark, faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function Contact() {
    const [actionContent, setActionContent] = useState(null)
    const [backBtn, setBackBtn] = useState(null)

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const reqObj = {};
        formData.forEach((e, key) => {
            reqObj[key] = e;
        });
        reqObj.type = e.target.id
        contactMail(reqObj)
            .then((res) => {
                onResponse(e.target, res.data.message, res.status)
            })
    };

    function onResponse(el, res, status) {
        el.style.transition = 'all 0.5s linear'
        el.style.transform = 'translateX(50%)'
        el.style.opacity = '0'
        setTimeout(() => {
            setActionContent(
                <div className='mail-feedback'>
                    {res}
                    {
                        status === 200 ?
                            <FontAwesomeIcon className='feedback-icon-ok' icon={faCheck}/> :
                            <FontAwesomeIcon className='feedback-icon-err' icon={faXmark}/>
                    }
                </div>
            )
        }, 500)
    }


    function changeDomEl(target) {
        let templateOptions
        switch (parseInt(target)) {
            case 1:
                templateOptions = (
                    <form id='employer' className='employer-form form-animation' onSubmit={handleSubmit}>
                        <Input type="text" name="company" label="nom de l'entreprise"/>
                        <Input type="name" name="name" label='votre nom' required/>
                        <Input type="email" name="email" label='email' required/>
                        <Input type="phone" name="phone" label='NUMÉRO DE TÉLÉPHONE'/>
                        <SelectOption type='select-unique' name="contract" label='Veuillez choisir un Type de contrat'
                                      options={['CDI', 'CDD', 'Freelance', 'Autre']} required/>
                        <Input type="textArea" name="description" rows={5} label='Description de votre projet / poste'
                               required/>
                        <Button type='submit'>Envoyer</Button>
                    </form>
                )
                break
            case 2:
                templateOptions = (
                    <form id='client' className='client-form form-animation' onSubmit={handleSubmit}>
                        <Input type="text" name="company" label="nom de l'entreprise"/>
                        <Input type="name" name="name" label='votre nom' required/>
                        <Input type="email" name="email" label='email' required/>
                        <Input type="phone" name="phone" label='NUMÉRO DE TÉLÉPHONE'/>
                        <Input type="textArea" name="description" rows={5} label='Parlez moi de votre projet' required/>
                        <div className='questions'>
                            <h2>Avez-vous ?</h2>
                            <hr/>
                        </div>
                        <DatePicker name="deadline" label='une Deadline'/>
                        <BudgetInput label='un Budget'/>
                        <Button type='submit'>Envoyer</Button>
                    </form>
                )
                break
            default:
                templateOptions = (
                    <form id='default' className='default-form form-animation' onSubmit={handleSubmit}>
                        <Input type="name" name="name" label='votre nom' required/>
                        <Input type="email" name="email" label='email' required/>
                        <Input type="phone" name="phone" label='NUMÉRO DE TÉLÉPHONE'/>
                        <Input type="textArea" name="description" rows={5} label='Comment puis-je vous aider' required/>
                        <Button type='submit'>Envoyer</Button>
                    </form>
                )
                break
        }
        setBackBtn(
            <button type="button" className='back-btn' onClick={restartForm}>
                <FontAwesomeIcon className='link-icon' icon={faArrowLeft}/>
            </button>
        )
        setActionContent(templateOptions)
    }

    function onChoice(e) {
        const btnElement = e.target
        const targetValue = btnElement.value

        //Pulse on click
        btnElement.classList.add('clicked')

        //slide out other btn
        const childrenBtn = Array.from(e.target.parentNode.children)
        childrenBtn.forEach((e) => {
            if (e.value !== targetValue) {
                e.style.transform = 'translateX(50%)'
                e.style.opacity = '0'
            }
        })

        setTimeout(() => {
            btnElement.classList.toggle('clicked')
            btnElement.style.transform = 'translateX(50%)'
            btnElement.style.opacity = '0'
        }, 550)

        setTimeout(() => {
            changeDomEl(targetValue)
        }, 1000)
    }
    function restartForm() {
        setBackBtn(null)
        setActionContent(
            <div className='action-choices'>
                <Button type={'button'} value={1} onClick={(e) => {
                    onChoice(e)
                }}>JE SUIS UN EMPLOYEUR</Button>
                <Button type={'button'} value={2} onClick={(e) => {
                    onChoice(e)
                }}>JE SOUHAITE UN DEVIS POUR UNE PRESTATION</Button>
                <Button type={'button'} value={3} onClick={(e) => {
                    onChoice(e)
                }}>Autre</Button>
            </div>
        )
    }

    useEffect(() => {
        restartForm()
    }, []);

    return (
        <section id='contact-section'>
            <div className="contact-container">
                <div className='contact-content'>
                    <div className="about-content">
                        <h2 className='main-title'>A propos</h2>

                        <p className="skill-description">Mon parcours a été marqué par une attitude proactive et un
                            souci du détail qui ont façonné ma trajectoire. Chez Desjardins, j'ai découvert ma passion
                            pour la résolution de problèmes techniques, me dirigeant naturellement vers le domaine
                            passionnant de la programmation et du développement web. Après une année de reconversion
                            dans le développement web full stack, je cherche à déployer mes compétences pour des projets
                            stimulants. Mon engouement pour la programmation et mon désir constant d'amélioration font
                            de moi un candidat motivé, capable d'offrir des solutions technologiques de qualité. Je suis
                            convaincu(e) que mon parcours diversifié et ma passion pour le développement web apporteront
                            une réelle valeur ajoutée à votre équipe.</p>
                    </div>

                    <div className='contact-actions'>

                        <div className="action-title">
                            {backBtn}

                            <h2 className='main-title'>Contactez-moi</h2>
                        </div>
                        {actionContent}
                    </div>
                </div>
            </div>
        </section>
    );
}
