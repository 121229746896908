import React, {useEffect, useState} from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import NavBar from "./Layout/NavBar";
import InfoBar from "./Layout/InfoBar";
import Footer from "./Layout/Footer";
import Routing from "./Utils/Routing";
import { isAuth } from "./Utils/Api";

function App() {
  const [isAdmin, setIsAdmin] = useState(false)
  const [projectInfo, setProjectInfo] = useState('')
  function isLogged(value) {
    setIsAdmin(value)
  }
  function getProjectInfo(value) {
    setProjectInfo(value)
  }

  useEffect(() => {
    isAuth()
        .then((res) => {
          setIsAdmin(res)
        })
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        {
          isAdmin ? <InfoBar mode='ADMIN' isLogged={isLogged}/> : <></>
        }
        <div className="main-container">
          <NavBar />
          <div className="content">
            <Routing isAdmin={isAdmin} isLogged={isLogged} getProjectInfo={getProjectInfo}/>
          </div>
          <Footer projectInfo={projectInfo}/>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
