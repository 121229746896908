import React, {useState, useRef, useEffect} from "react";
import "./Skills.css";
import Logo from "../Components/Logo";
import parseXMLStringToReactElements from "../Utils/parseXMLStringToReactElements";
import AdminEdition from "../Components/AdminEdition";
import {skillGet, workGetBySKill} from "../Utils/Api";
import Button from "../Components/Button";
import {useNavigate} from "react-router-dom";

export default function Skills(props) {
  const navigate = useNavigate()
  const descriptionRef = useRef(null);
  const logoContainerRef = useRef(null);
  const [title, setTitle] = useState("");
  const [titleImg, setTitleImg] = useState(null);
  const [isChanging, setIsChanging] = useState(false);
  const [skillArray, setSkillArray] = useState([]);
  const [skillTarget, setSkillTarget] = useState(null);
  const [description, setDescription] = useState("");
  const [projects, setProjects] = useState(null);
  const [updateData, setUpdateData] = useState(false);

  useEffect(  () => {
    skillGet()
        .then((res) => {
          res.map(el => {
            return el.isActive = false;
          })
          setSkillArray(res)
        })
  }, [updateData]);

  function onUpdateData(){
    setUpdateData(!updateData)
  }
  function showSkillDetails(id) {
    const children = Array.from(descriptionRef.current.children);
    children.forEach((e) => {
      e.style.transition = `none`;
      e.style.transform = "translateX(150px)";
    });
    const skillObject = skillArray.find((skill) => skill.id === id);

    setSkillTarget(skillObject)
    setTitle(skillObject.title);
    setDescription(skillObject.description);
    setTitleImg(
      <div className="svgClass">
        {parseXMLStringToReactElements(skillObject.svgCode)}
      </div>
    );
    workGetBySKill(id).then((res) => {
      let workLink = '';
      res?.forEach(work => {
        workLink =
            <>
              <Button type="button" onClick={() => {
                navigate('/portfolio/' + work.id)
              }}>{work.title}</Button>
              {workLink}
            </>
      })
      setProjects(<div className="skill-project">{workLink}</div>)
    })
    return new Promise(function (resolve) {
      setTimeout(resolve, 100);
    });
  }

  function animationOut() {
    const children = Array.from(descriptionRef.current.children);
    children.forEach((e, i) => {
      e.style.transition = `all ${(i + 2) * 0.2}s ease-in-out`;
      e.style.transform = "translateX(-150px)";
      e.style.opacity = "0";
    });
    return new Promise(function (resolve) {
      setTimeout(resolve, 600);
    });
  }

  function animationIn() {
    const childrens = Array.from(descriptionRef.current.children);
    childrens.forEach((e, i) => {
      e.style.transition = `all ${(i + 2) * 0.2}s ease-in-out`;
      e.style.transform = "translateX(0px)";
      e.style.opacity = "1";
    });
    return new Promise(function (resolve) {
      setTimeout(resolve, 600);
    });
  }

  async function allAnimation(id) {
    if (!isChanging) {
      await animationOut();
      await showSkillDetails(id);
      await animationIn();
      setIsChanging(false);
    }
  }

  const updateSkillArray = (id) => {
    const updatedSkillArray = skillArray.map((e) => {
      if (e.id === id) {
        return { ...e, isActive: true };
      } else {
        return { ...e, isActive: false };
      }
    });
    setSkillArray(updatedSkillArray);
  };
  
  // useEffect(() => {
  //   if (skillArray.length > 0) {
  //     setLogoContent(
  //       <>
  //        {
  //         skillArray.map((e) => (
  //           <Logo
  //             key={e.id}
  //             code={e.svgCode}
  //             isActive={e.isActive}
  //             onClick={() => {
  //               setIsChanging(true);
  //               allAnimation(e.id);
  //             }}
  //           />
  //         ))
  //        }
  //       </>
  //     )
  //   }

  // }, [skillArray])

  return (
    <section className="skill-section">
      <div className="skill-content">
        <div ref={logoContainerRef} className="logo-container">
          <div className="logo-wrapper">
            <div>
              {skillArray.length > 0 && skillArray.map((e) => (
                <Logo
                  key={e.id}
                  code={e.svgCode}
                  isActive={e.isActive}
                  onClick={() => {
                    setIsChanging(true);
                    allAnimation(e.id);
                    updateSkillArray(e.id)
                  }}
                />
              ))}
              {
                  props.isAdmin && <AdminEdition topic='add-skill' method='POST' onUpdate={onUpdateData}/>
              }
            </div>
          </div>
        </div>
        <div className="description-container">
          <div ref={descriptionRef} className="description-wrapper">
            <div className="skill-title">
              {titleImg}
              <h2>{title}</h2>
            </div>
            <div className="skill-description">{description}</div>
            {
                props.isAdmin &&
                <div className='admin-actions'>
                  <AdminEdition
                      topic='edit-skill'
                      method='PUT'
                      data={skillTarget}
                      onUpdate={onUpdateData}
                  />
                  <AdminEdition
                      topic='delete-skill'
                      method='DELETE'
                      data={skillTarget}
                      onUpdate={onUpdateData}
                  />
                </div>
            }
            {projects}
          </div>
        </div>
      </div>
    </section>
  );
}
