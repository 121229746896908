import React, {useState} from "react";
import './SelectOption.css'

export default function SelectOption(props) {
    const [isFilled, setIsFilled] = useState(false);

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        setIsFilled(inputValue !== "");
        if (props.onChange) {
            props.onChange(inputValue);
        }
    };

    return (
        <div className="input-container">
            <label htmlFor="select-input" className={isFilled ? "form__label active" : "form__label"}>{props.label}</label>
            <select name={props.name} id="select-input" onChange={handleInputChange} required={props.required}>
                <option value="" defaultValue></option>
                {props.options?.map((e) => {
                    return <option key={e} value={e}>{e}</option>
                })}
            </select>
        </div>

    )
}