import React from "react";
import "./Home.css";

export default function Home(props) {
  return (
    <section id='Home-section' className="home-container">
      <div className="presentation">
        Développeur Full Stack Explorateur du web et créateur d'expériences
      </div>
      <div className="objectives">
        Mon objectif : repousser les limites de l'innovation technologique pour
        offrir des expériences en ligne remarquables. Alliant créativité et
        expertise technique, je conçois des sites web et des applications
        dynamiques, intuitifs et esthétiques.
      </div>
    </section>
  );
}
