import React, {useRef, useState} from "react";
import './DatePicker.css'

import {faCalendarDays} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function DatePicker(props) {
    const dateInputRef = useRef(null)
    const [isFilled, setIsFilled] = useState(false);
    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        setIsFilled(inputValue !== "");
        if (props.onChange) {
            props.onChange(inputValue);
        }
    };

    function openCalender() {
        if (dateInputRef) {
            dateInputRef.current.showPicker()
            dateInputRef.current.focus()
        }
    }
    return(
        <div className="input-container">
            <label className={isFilled ? "form__label active" : "form__label"}>{props.label}</label>
            <input
                ref={dateInputRef}
                type="date"
                name={props.name}
                className="datepicker-input"
                style={{
                    color: !isFilled ? 'transparent' : "inherit"
                }}
                onChange={handleInputChange}
            />
            <FontAwesomeIcon
                className='icon'
                icon={faCalendarDays}
                onClick={openCalender}
            />
        </div>
    )
}