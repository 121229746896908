import React, { useState, useEffect } from "react";
import parseXMLStringToReactElements from "../Utils/parseXMLStringToReactElements";
import "./Logo.css";

export default function Logo(props) {
  const [hovered, setHovered] = useState(false);

  const reactElements = parseXMLStringToReactElements(props.code);

  return (
    <div 
      className={!props.isActive ? "logo-icon" : "logo-icon active"} 
      onClick={props.onClick}
    >
      {reactElements}
    </div>
  );
}
