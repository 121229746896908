import React, {useState} from "react";
import './BudgetInput.css'
export default function BudgetInput(props){
    const [isFilled, setIsFilled] = useState(false);

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        setIsFilled(inputValue !== "");
        if (props.onChange) {
            props.onChange(inputValue);
        }
    };

    return(
        <div className="input-container">
            <label htmlFor="select-input" className={isFilled ? "form__label active" : "form__label"}>{props.label}</label>
            <div className='currency-input'>
                <input
                    className="form__input"
                    type="number"
                    name="budget"
                    min="1"
                    onChange={handleInputChange}
                />
                <select name="currency" className='currency-select'>
                    <option value="EUR" defaultValue>EUR</option>
                    <option value="CAD">CAD</option>
                    <option value="USD">USD</option>
                    <option value="DA">DA</option>
                </select>
            </div>
        </div>
    )
}