import React, {useRef, useState} from "react";
import "./NavBar.css";
import {NavLink, useLocation, useNavigate} from "react-router-dom";

export default function NavBar() {
    let location = useLocation();
    const navigate = useNavigate()
    const selectionRef = useRef(null);
    const isTouchDevice = ('maxTouchPoints' in navigator && navigator.maxTouchPoints > 0);
    const [currentNav, setCurrentNav] = useState(location.pathname.split('/')[1] ? location.pathname.split('/')[1] : 'Home')

    return (
        <nav id="navbar">
            <div className="nav-logo" onClick={() => {
                navigate('/')
                setCurrentNav('HOME')
            }}>
                <img src={process.env.PUBLIC_URL + "/logo.svg"} alt="Mon logo"/>
                <h2>Mohammed-Walid Zeghoudi</h2>
            </div>
            {isTouchDevice ?
                <div className="mobile-options">
                    <div className='selected' onClick={() => {
                        if (selectionRef) {
                            selectionRef.current.classList.add('open')
                        }
                    }}>
                        {currentNav}
                    </div>
                    <div ref={selectionRef} className='selection '
                         onClick={(e) => {
                             const optionsDim = selectionRef.current.firstChild.getBoundingClientRect()
                             if (
                                 e.clientX < optionsDim.left ||
                                 e.clientX > optionsDim.right ||
                                 e.clientY < optionsDim.top ||
                                 e.clientY > optionsDim.bottom) {
                                 selectionRef.current.classList.remove('open')
                             }
                         }}>
                        <div className='options'>
                            <div onClick={() => {
                                navigate('/competences')
                                setCurrentNav('COMPETENCES')
                                selectionRef.current.classList.remove('open')
                            }}>
                                COMPETENCES
                            </div>
                            <div onClick={() => {
                                navigate('/portfolio')
                                setCurrentNav('PORTFOLIO')
                                selectionRef.current.classList.remove('open')
                            }}>
                                PORTFOLIO
                            </div>
                            <div onClick={() => {
                                navigate('/contact')
                                setCurrentNav('CONTACT')
                                selectionRef.current.classList.remove('open')
                            }}>
                                CONTACT
                            </div>
                        </div>
                    </div>
                </div> :
                <div className="options">
                    <div>
                        <div>
                            <NavLink to={"/competences"} tabIndex={0}>
                                COMPETENCES
                            </NavLink>
                        </div>
                        <div className="slash"></div>
                        <div>
                            <NavLink to={"/portfolio"} tabIndex={0}>
                                WORK
                            </NavLink>
                        </div>
                        <div className="slash"></div>
                        <div>
                            <NavLink to={"/contact"} tabIndex={0}>
                                CONTACT
                            </NavLink>
                        </div>
                    </div>
                </div>
            }
        </nav>
    );
}
