import React from 'react'
import './LinkButton.css';

export default function LinkButton(props) {
  return (
    <a className='link-btn' href={props.link} target={props.target}>
        {props.icon}
        <span className='link-text'>{props.title}</span>
    </a>
  )
}