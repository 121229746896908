import React, {useEffect, useState} from "react";
import './MultiSelectOption.css'
export default function MultiSelectOption({getValues, ...props}){
    const [options, setOptions] = useState([])
    const [values, setValues] = useState([])
    const [change, setChange] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    useEffect(() => {
        getValues(values)
    }, [change]);
    return (
        <div className="multiselect-container">
            <div className="multiselect-display" onClick={() => {
                setIsOpen(!isOpen)
            }}>
                <div className={`multiselect-label${(options.length > 0) ? ' active' : ''}`}>{props.label}</div>
                {options.map((e) => {
                    return <span className="multiselect-selected">{e}</span>
                })}
            </div>
            <div className={`multiselect-options-wrapper${isOpen ? ' open' : ''}`}>
                <div className="multiselect-options">
                    {
                        props.options?.map((e) => {
                            return (
                                <label key={e.value} className="multiselect-option">
                                    <input
                                        type="checkbox"
                                        className="multiselect-input"
                                        value={e.value}
                                        data-name={e.label}
                                        onChange={(el) => {

                                        const checkBox = el.target;
                                        checkBox.parentNode.className = `multiselect-option${checkBox.checked ? ' checked' : ''}`

                                        const optionsTMP = options
                                        const valuesTMP = values
                                        const optionIndex = optionsTMP.indexOf(checkBox.dataset.name);
                                        const valueIndex = optionsTMP.indexOf(checkBox.value);

                                        if (optionIndex !== -1) {
                                            optionsTMP.splice(optionIndex, 1);
                                            valuesTMP.splice(valueIndex, 1);
                                        } else {
                                            optionsTMP.push(checkBox.dataset.name)
                                            valuesTMP.push(checkBox.value)
                                        }

                                        setValues(valuesTMP)
                                        setOptions(optionsTMP)
                                        setChange(!change)
                                    }} />
                                    <span className="multiselect-text">{e.label}</span>
                                </label>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}