import React, {useEffect, useState} from "react";
import "./Project.css";
import Video from "../Components/Video";

import {faCirclePlay, faCirclePause, faCodeBranch, faServer} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams, useNavigate } from "react-router-dom";
import {workGet} from "../Utils/Api";
import LinkButton from "../Components/LinkButton";

export default function Project(props) {
    const { id } = useParams();
    const navigate = useNavigate();
    const [pause, setPause] = useState(false)
    const [isMobile, setIsMobile] = useState(false)
    const [isTouchDevice, setIsTouchDevice] = useState(false);
    const [isOnVideo, setIsOnVideo] = useState(false)
    const [project, setProject] = useState(null)
    const [description, setDescription] = useState(null)
    const [objective, setObjective] = useState(null)

    useEffect(() => {
        workGet(id).then(res => {
            props.getProjectInfo(...res);
            setProject(...res)
        })
        setIsTouchDevice('maxTouchPoints' in navigator && navigator.maxTouchPoints > 0);
    }, []);

    useEffect(() => {
        if (window.innerWidth <= 500 && isTouchDevice){
            setIsMobile(true)
        }
    }, [isTouchDevice]);
    useEffect(() => {
        if (project && project.description){
            console.log('ici');
            let text = project.description
            text = text.split('.')
            setObjective(text[0])
            let formatedTxt = ''
            text.forEach((el, i) => {
                if (i < (text.length - 1))
                formatedTxt =
                    <>
                        {formatedTxt}
                        {el}.
                        <br />
                        <br />
                    </>
            })
            setDescription(formatedTxt)
        } else if(project && project.message) {
            navigate('/not-found')
        }
    }, [project]);

  return (
    <section
      className="project-container"
    >
        <div
            className='project-video'
            onMouseEnter={() => setIsOnVideo(true)}
            onMouseLeave={() => setIsOnVideo(false)}
        >
            {
                project &&
                <div className="video-display">
                    <Video
                        id="myVideo"
                        isPaused={pause}
                        muted
                        loop
                        overView={false}
                        src={project.demoLink}
                        type="video/webm"
                        error='Votre navitgateur ne supporte pas les video HTML5.'
                    />
                    <button id='video-btn' onClick={() => {setPause(!pause)}} className={isOnVideo ? 'show' : ''}>
                        <FontAwesomeIcon icon={pause ? faCirclePlay : faCirclePause} />
                    </button>
                </div>
            }
            {
                !isMobile ?
                <div className={`video-content ${!pause ? 'isPaused' : ''}`}>
                    <div className='text'>
                        {description}
                    </div>
                </div> :
                    <p className='text'>
                        {description}
                    </p>
            }
        </div>
    </section>
  );
}
