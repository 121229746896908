import axios from "axios";

const API_URL = 'https://api.zeghoudi-mohammed-walid.fr';
// const API_URL = 'http://localhost:4000';
const API_ROUTES = {
    SIGN_IN: `${API_URL}/auth/login`,
    CHECK_AUTH: `${API_URL}/auth/is-auth`,

    SKILL_GET: `${API_URL}/skill/`,
    SKILL_ADD: `${API_URL}/skill/add`,
    SKILL_EDIT: `${API_URL}/skill/`,

    WORK_GET: `${API_URL}/work/`,
    WORK_ADD: `${API_URL}/work/add`,
    WORK_EDIT: `${API_URL}/work/`,

    CONTACT_SEND: `${API_URL}/contact/send-mail`,
};

export async function contactMail(formData) {
    try {
        return await axios.post(`${API_ROUTES.CONTACT_SEND}`, formData)
    } catch (err) {
        console.error(err)
        return err
    }
}

export async function isAuth() {
    let isAuth = false
    const bearerToken = localStorage.token;
    if (bearerToken) {
        await axios.get(`${API_ROUTES.CHECK_AUTH}`, {
            headers: {
                Authorization: `Bearer ${bearerToken}`,
            },
        })
            .then((res) => {
                if (res.status === 200) isAuth = true
                return isAuth
            })
            .catch((err) => {
                console.log(err)
                localStorage.removeItem("token")
                window.location.reload();
            });
    }
    return isAuth
}

export async function skillGet(id = null) {
    let response = []
    await axios.get(`${API_ROUTES.SKILL_GET}${id ? id : 'list'}`)
        .then((res) => {
            if (res.status === 200) {
                const data = res.data
                if (data.length) {
                    data.forEach(skill => {
                        const obj = {
                            ...skill,
                            id: skill._id
                        }
                        delete obj._id
                        delete obj.__v
                        response.push(obj)
                    })
                } else {
                    const obj = {
                        ...data,
                        id: data._id
                    }
                    delete obj._id
                    delete obj.__v
                    response.push(obj)
                }
            }
        })
        .catch((err) => {
            response = {
                message: 'Une erreur s\' produite',
                error: err
            };
        });
    return response
}
export async function skillOptions() {
    await isAuth();
    let response = []
    await axios.get(`${API_ROUTES.SKILL_GET}options`)
        .then((res) => {
            if (res.status === 200) {
                response = res.data
            }
        })
        .catch((err) => {
            response = [{message: 'Une erreur s\' produite'}];
        });
    return response
}

export async function skillPosts(method, data) {
    await isAuth();
    const bearerToken = localStorage.token;
    let message = ''
    if (method === 'POST') {
        await axios.post(`${API_ROUTES.SKILL_ADD}`, data, {
            headers: {
                Authorization: `Bearer ${bearerToken}`,
            },
        })
            .then((res) => {
                if (res.status === 201) message = 'Competence Ajoute'
                return message
            })
            .catch((err) => {
                console.log(err)
                message = 'Une erreur s\' produite'
            });
    } else {
        const skillId = data.id
        delete data.id
        await axios.put(`${API_ROUTES.SKILL_EDIT}${skillId}`, data, {
            headers: {
                Authorization: `Bearer ${bearerToken}`,
            },
        })
            .then((res) => {
                if (res.status === 200) message = 'Competence modifie'
                return message
            })
            .catch((err) => {
                console.log(err)
                message = 'Une erreur s\' produite'
            });
    }
    return message;
}

export async function skillDelete(id) {
    await isAuth();
    const bearerToken = localStorage.token;
    let response = []
    await axios.delete(`${API_ROUTES.SKILL_EDIT}${id}`, {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
        },
    })
        .then((res) => {
            if (res.status === 200) {
                response = [{message: 'Competence supprimer avec succes'}];
            }
        })
        .catch((err) => {
            response = [{message: 'Une erreur s\' produite'}];
        });
    return response
}

export async function workGet(id = null) {
    let response = []
    await axios.get(`${API_ROUTES.WORK_GET}${id ? id : 'list'}`)
        .then((res) => {
            if (res.status === 200) {
                const data = res.data
                if (data.length) {
                    data.forEach(work => {
                        const dateObj = new Date(work.hostingDate)
                        const formatedDate = dateObj.toLocaleDateString()
                        const obj = {
                            ...work,
                            id: work._id
                        }
                        delete obj._id
                        delete obj.__v
                        delete obj.hostingDate
                        obj.hostingDate = formatedDate
                        response.push(obj)
                    })
                } else {
                    const dateObj = new Date(data.hostingDate)
                    const formatedDate = dateObj.toLocaleDateString()
                    const obj = {
                        ...data,
                        id: data._id
                    }
                    delete obj._id
                    delete obj.__v
                    delete obj.hostingDate
                    obj.hostingDate = formatedDate
                    response.push(obj)
                }
            }
        })
        .catch((err) => {
            response = [{message: 'Une erreur s\' produite' + err}];
        });
    return response
}

export async function workPost(method, data) {
    await isAuth();
    const bearerToken = localStorage.token;
    let message = ''
    if (method === 'POST') {
        console.log(data)
        await axios.post(`${API_ROUTES.WORK_ADD}`, data, {
            headers: {
                Authorization: `Bearer ${bearerToken}`,
            },
        })
            .then((res) => {
                if (res.status === 201) message = 'Projet ajoute'
                return message
            })
            .catch((err) => {
                console.log(err)
                message = 'Une erreur s\' produite'
            });
    } else {
        const workId = data.id
        delete data.id
        await axios.put(`${API_ROUTES.WORK_EDIT}${workId}`, data, {
            headers: {
                Authorization: `Bearer ${bearerToken}`,
            },
        })
            .then((res) => {
                if (res.status === 200) message = 'Competence modifie'
                return message
            })
            .catch((err) => {
                console.log(err)
                message = 'Une erreur s\' produite'
            });
    }
    return message;
}

export async function workGetBySKill(skill) {
    let response = []
    await axios.get(`${API_ROUTES.WORK_GET}${skill}/works`)
        .then((res) => {
            if (res.status === 200) {
                const data = res.data
                if (data.length > 0) {
                    data.forEach(work => {
                        const obj = {
                            ...work,
                            id: work._id
                        }
                        delete obj._id
                        delete obj.__v
                        response.push(obj)
                    })
                }
            }
        })
        .catch((err) => {
            response = [{message: 'Une erreur s\' produite' + err}];
        });

    return response
}
