import React, { useEffect, useState } from "react";
import LinkButton from "../Components/LinkButton";
import { useLocation } from "react-router-dom";
import "./Footer.css";
import {faCodeBranch, faServer} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Footer(props) {
  let location = useLocation();
  const [pageTitle, setPageTitle] = useState('');
  const [pageLinks, setPageLinks] = useState('');
  const [pageTagLine, setPageTagLine] = useState('');
  const [isDisplay, setIsDisplay] = useState(true);

  useEffect(() => {
    let pageTitleTMP = location.pathname.split('/')
    let pageLinkTMP = '';
    let pageTagLineTMP = '';

    if (pageTitleTMP.length > 2 && props.projectInfo){
      pageTitleTMP = props.projectInfo.title
      pageTagLineTMP = props.projectInfo.description.split('.')[0]
      if (props.projectInfo.repoLink) {
        pageLinkTMP = 
        <>
          {pageLinkTMP}
          <LinkButton
              icon={<img className={'link-svg'}  src={`${process.env.PUBLIC_URL}/github.svg`}/>}
              title={'repo'}
              link={props.projectInfo.repoLink}
          />
        </>
      }

      if (props.projectInfo.hostedLink) {
        pageLinkTMP = 
        <>
          {pageLinkTMP}
          <LinkButton
              icon={<FontAwesomeIcon className='link-icon' icon={faServer} />}
              title={'hosted'}
              link={props.projectInfo.hostedLink}
          />
        </>
      }
    } else {
      pageTitleTMP = pageTitleTMP[1]
      if(pageTitleTMP == 'contact') {
        pageTagLineTMP = 'Votre projet mérite une attention personnalisée. Contactez-moi pour en parler.'
        pageLinkTMP = 
          <>
            <LinkButton
                icon={<img className={'link-svg'} src={`${process.env.PUBLIC_URL}/github.svg`} />}
                title='Github'
                link="https://github.com/iLnsiC"
              />
              <LinkButton
                icon={<img className={'link-svg'} src={`${process.env.PUBLIC_URL}/linkedIn.svg`} />}
                title='LinkedIn'
                link="https://www.linkedin.com/in/mohammed-walid-zeghoudi/"
              />
          </>
      }
      if(pageTitleTMP == 'portfolio') {
        pageTagLineTMP = 'Découvrez un aperçu de mon travail à travers des projets conçus avec passion, mêlant esthétisme audacieux et expérience utilisateur optimale.'
      }
      if(pageTitleTMP == 'competences') {
        pageTagLineTMP = 'En tant que développeur web, je fusionne créativité et technologie  spour concevoir des interfaces intuitives, des animations captivantes et une expérience utilisateur de premier ordre.'
      }
    }
    if(pageTitleTMP == '') {
      setIsDisplay(false)
    }else{
      setIsDisplay(true)
    }
    setPageTagLine(pageTagLineTMP);
    setPageLinks(pageLinkTMP);
    setPageTitle(pageTitleTMP);
  }, [location, props.projectInfo])
  
  return (
    <footer className={isDisplay ? "footer-container" : "footer-container hide"}>
      <div className="page-data">
        <div className="page-title-container">
          <h1 className="page-title">{pageTitle}</h1>
          {pageLinks}
        </div>
        <div className="objectives">
          {pageTagLine}
        </div>
      </div>
    </footer>);
}
